import { template as template_5fa85ab61efc4dabb71e1e30f6e2c2c6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_5fa85ab61efc4dabb71e1e30f6e2c2c6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
