import { template as template_dc3e847a9af445b1b45d9d31ade5b92e } from "@ember/template-compiler";
const FKText = template_dc3e847a9af445b1b45d9d31ade5b92e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
